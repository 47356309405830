import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Section3AMelhorWebserieGratuita = styled.section`
  background-color: white;
  padding: 40px 0;
  overflow: hidden;

  @media ${device.tablet} {
    padding: 76.5px 0;
  }

  @media ${device.desktopLG} {
    padding: 96px 0;
  }

  @media ${device.desktopXXL} {
    padding: 143px 0;
  }
`

export const Title = styled.h2`
  font-family: "Citrina", Helvetica, sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: left;
  color: ${grayscale[500]};

  margin-bottom: 16px;
  
  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 48px;
  }
  
`

export const Description = styled.p`
  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: left;
  color: ${grayscale[400]};

  margin-bottom: 32px;

  @media ${device.tablet} {
    margin-bottom: 40px;
  }

  @media ${device.desktopLG} {
    font-size: 18px;
    line-height: 22px;
  }
`

export const Texts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.desktopLG} {
    padding-right: 32px;
  }

  @media ${device.desktopXXL} {
    padding-right: 12px;
  }
`

export const Carousel = styled.div`
  margin-top: 64px;

  @media ${device.tablet} {
    position: absolute;
    right: 0;
    padding: 0 0 0 12px;
    margin-top: 0px;
  }

  @media ${device.desktopLG} {
    padding-left: 56px;
  }

  @media ${device.desktopXXL} {
    padding-left: 48px;
  }
  
  .react-multi-carousel-track {
    @media ${device.tablet} {
      padding: 0 0 50px 0;
    }
  }
  .carousel-container {
    overflow: visible;

    @media ${device.tablet} {
      overflow: hidden;
    }
  }
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0 64px;


  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 0;
  }
`
