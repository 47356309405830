import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from "styled-components"

export const Section = styled.section`
  padding: 40px 0;

  @media ${device.desktopLG} {
    padding: 64px 0;
  }

  @media ${device.desktopXXL} {
    padding: 96px 0;
  }
`

export const Title = styled.h2`
  font-family: "Citrina", Helvetica, sans-serif;
  font-weight: 700;
  font-size: 28px;
  line-height: 34px;
  text-align: left;
  color: ${grayscale[500]};

  margin-bottom: 16px;
  
  @media ${device.desktopLG} {
    font-size: 40px;
    line-height: 48px;
  }
`
