import { device } from 'src/styles/breakpoints'
import { grayscale } from 'src/styles/colors'
import styled from 'styled-components'

export const Section1AprendaTudoSobreCriptomoedas = styled.section`
  padding: 40px 0;
  background-color: ${grayscale[500]};
  min-height: 44vw;
  display: flex;
  align-items: center;
  
  @media ${device.tablet} {
    padding: 64px 0;
  }

  @media ${device.desktopLG} {
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/lp-tudo-sobre-cripto-section-1-background-lg/image.webp);
    background-size: cover;
    background-position: center;
  }

  @media ${device.desktopXXL} {
    background-image: url(https://central-imagens.bancointer.com.br/images-without-small-versions/lp-tudo-sobre-cripto-section-1-background-xxl/image.webp);
    padding: 96px 0;
  }
`

export const Image = styled.picture`
  display: flex;
  margin: 0 auto;
  width: 200px;

  margin-bottom: 40px;

  @media ${device.tablet} {
    width: 310px;
    margin-left: 0;
    margin-bottom: 16px;
  }
`

export const ImageSource = styled.img`
  width: 100%;
  height: auto;
`

export const Title = styled.h1`
  font-family: "Citrina", Helvetica, sans-serif;
  font-weight: 600;
  font-size: 32px;
  line-height: 39px;
  text-align: left;
  color: white;

  margin-bottom: 16px;

  @media ${device.tablet} {
    font-size: 48px;
    line-height: 53px;
  }
`

export const Description = styled.div`
  font-family: "Inter", Helvetica, sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: left;
  color: white;

  margin-bottom: 24px;

  @media ${device.tablet} {
    font-size: 18px;
    line-height: 22px;
    
    margin-bottom: 0;
  }

  @media ${device.desktopLG} {
    width: 90%;
  }
`

export const SubscriptionForm = styled.form`
  display: flex;
  flex-direction: column;
  padding: 24px;

  @media ${device.desktopLG} {
    background-color: ${grayscale[500]};
    border-radius: 16px;
  }

  .input {
    label {
      color: white;
    }
  }

  .accept_terms {
    font-family: "Inter", Helvetica, sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    color: white;

    u {
      color: white;
      text-decoration: none;
    }
  }
`

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
`

export const TextContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`
