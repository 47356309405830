import { device } from 'src/styles/breakpoints'
import styled from 'styled-components'

export const Title = styled.h2`
  font-family: 'Citrina', Helvetica, sans-serif;
`
export const BG = styled.section`
  background-image: url('https://central-imagens.bancointer.com.br/images-without-small-versions/lp-tudo-sobre-cripto-section-4-background/image.webp');
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media ${device.tablet} {
    height: 250px;
  }

  @media ${device.desktopLG} {
    height: 350px;
  }

  @media ${device.desktopXXL} {
    height: 500px;
  }
`
