import { device } from 'src/styles/breakpoints';
import styled, { css } from 'styled-components';

type CardWrapperProps = {
  backgroundImage: string;
}

const cardTextsCommomStyles = css`
  display: flex;
  
  font-family: "Inter", Helvetica, sans-serif;
  
  text-align: left;
  color: white;
`

export const CardWrapper = styled.article<CardWrapperProps>`
  background-image: url(${(props: CardWrapperProps) => (props.backgroundImage)});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 136px;
  height: 198px;
  
  padding: 16px;
  border-radius: 12px;

  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  
  @media ${device.desktopXXL} {
    width: 168px;
    height: 264px;
  }
  
  .module_and_title {
    transition: all 0.5s ease-in-out;
    
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    position: absolute;
    top: 0;
    left: 0;

    width: 136px;
    height: 198px;
    padding: 16px;
    
    @media ${device.desktopXXL} {
      width: 168px;
      height: 264px;
    }

    .module {
      ${cardTextsCommomStyles}
      
      font-size: 14px;
      line-height: 17px;
      font-weight: 400;
      
      margin-bottom: 12px;
      
      @media ${device.desktopXXL} {
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 16px;
      }
    }
    
    .title {
      ${cardTextsCommomStyles}
      
      font-size: 12px;
      line-height: 14px;
      font-weight: 600;
      
      height: 60px;
      
      @media ${device.desktopXXL} {
        height: 95px;

        font-size: 16px;
        line-height: 19px;

      }
    }
  }

  .description_with_mask {
    padding: 16px;
    position: absolute;
    bottom: -100%;
    left: 0;
    
    transition: all 0.5s ease-in-out;
    background-color: rgba(0, 0, 0, 0.7);
    
    display: flex;
    align-items: center;
    justify-content: center;

    width: 136px;
    height: 198px;
 
    ${cardTextsCommomStyles}

    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    
    @media ${device.desktopLG} {
      font-size: 16px;
      line-height: 19px;
    }

    @media ${device.desktopXXL} {
      width: 168px;
      height: 264px;

      font-size: 18px;
      line-height: 22px;
    }
  }
  
  &:hover {
    .module_and_title {
      top: -100%;
    }
    .description_with_mask {
      bottom: 0;
    }
  }
`
